exports.components = {
  "component---src-pages-contact-index-en-tsx": () => import("./../../../src/pages/contact/index.en.tsx" /* webpackChunkName: "component---src-pages-contact-index-en-tsx" */),
  "component---src-pages-contact-index-vi-tsx": () => import("./../../../src/pages/contact/index.vi.tsx" /* webpackChunkName: "component---src-pages-contact-index-vi-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-vi-tsx": () => import("./../../../src/pages/index.vi.tsx" /* webpackChunkName: "component---src-pages-index-vi-tsx" */),
  "component---src-pages-projects-index-en-tsx": () => import("./../../../src/pages/projects/index.en.tsx" /* webpackChunkName: "component---src-pages-projects-index-en-tsx" */),
  "component---src-pages-projects-index-vi-tsx": () => import("./../../../src/pages/projects/index.vi.tsx" /* webpackChunkName: "component---src-pages-projects-index-vi-tsx" */),
  "component---src-pages-services-index-en-tsx": () => import("./../../../src/pages/services/index.en.tsx" /* webpackChunkName: "component---src-pages-services-index-en-tsx" */),
  "component---src-pages-services-index-vi-tsx": () => import("./../../../src/pages/services/index.vi.tsx" /* webpackChunkName: "component---src-pages-services-index-vi-tsx" */),
  "component---src-pages-team-index-en-tsx": () => import("./../../../src/pages/team/index.en.tsx" /* webpackChunkName: "component---src-pages-team-index-en-tsx" */),
  "component---src-pages-team-index-vi-tsx": () => import("./../../../src/pages/team/index.vi.tsx" /* webpackChunkName: "component---src-pages-team-index-vi-tsx" */),
  "component---src-templates-projects-index-en-tsx": () => import("./../../../src/templates/projects/index.en.tsx" /* webpackChunkName: "component---src-templates-projects-index-en-tsx" */),
  "component---src-templates-projects-index-vi-tsx": () => import("./../../../src/templates/projects/index.vi.tsx" /* webpackChunkName: "component---src-templates-projects-index-vi-tsx" */),
  "component---src-templates-services-index-en-tsx": () => import("./../../../src/templates/services/index.en.tsx" /* webpackChunkName: "component---src-templates-services-index-en-tsx" */),
  "component---src-templates-services-index-vi-tsx": () => import("./../../../src/templates/services/index.vi.tsx" /* webpackChunkName: "component---src-templates-services-index-vi-tsx" */)
}

